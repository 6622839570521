export const ConfigConstants = {
  hydra: {
    url: '/hydra',
    client_id: 'judge-web',
    client_scopes: 'openid offline',
    root_url: 'https://judge.testifysec.localhost',
  },
  registrar_svc: '/registrar',
  auth_svc: '/auth',
  gateway_svc: '/gateway',
  judgeapi_svc: '/judge-api',
};

export const inputValidation = {
  name: {
    pattern: '(?!.* {2})[A-Za-z0-9][A-Za-z0-9_ \\-\\(\\)]{2,63}',
    message: 'Name must be 3 to 64 characters long, start with an alphanumeric character, and may contain underscores, hyphens, single spaces, & parentheses.',
  },
  description: {
    pattern: '^[A-Za-z0-9][^$%^><!=*~`]+$',
    message: 'Description must not contain any of the following characters: $, %, ^, <, >, !, =, *, `, or ~',
  },
};
